
































import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'LinkAccount' })
export default class LinkAccount extends Vue {
  i18n_namespace = 'components.onboarding-kuspit.link_account';

  endProcess() {
    this.$emit('endProcess');
  }
}

